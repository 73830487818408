import * as React from "react"
import Layout from "../Components/Layout";
import Title from "../Components/Title";
import Image from "../Components/ImageQueries/LargeFeaturesImages";


const WhatWeDo = () => {
    return (
        <Layout customFoot>
           <div className='relative'>
               <Image imageName='semi-circle.png' className='privacy-img absolute xl:top-[128px] xl:left-[90%] xl:w-[390px] lg:w-[350px] md:left-[80%] w-0'/>
               <section className='text-[16px] mt-[106px] max-w-[1440px] xl:mx-auto relative'>
                   <div className='lg:ml-[160px] lg:px-0 px-8 max-w-[733px]'>
                       <p className='font-sans text-primary mb-[24px]'>EFFECTIVE AS OF December 16th, 2019.</p>
                       <Title className='text-[42px] mb-[24px]' style={{ marginTop: 0, marginBottom: 24}} level={2} symbol='.' withBlueSymbol>SpotOn privacy policy</Title>
                       <Title className='text-[24px] mb-[24px]' style={{ marginTop: 0}} level={4} >SpotOn privacy policy for consumers.</Title>
                       <p className='font-sans leading-[140%] text-[20px]'>SpotOn Transact, LLC (“SpotOn”) provides certain services directly to individual users like you (“Consumers”), and also provides technology for use by merchants (together, the “Services”). Merchants that are customers of SpotOn (“Merchants”) may provide certain information about you to SpotOn, and SpotOn may provide certain information about you to Merchants.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>This Privacy Policy covers only how SpotOn treats information relating to you, whether that information comes from your direct interaction with SpotOn or comes from Merchants with which you interact. Each Merchant has agreed with SpotOn to treat information relating to Consumers that it obtains from SpotOn or as a result of the Merchant’s use of SpotOn technology and Services as described in the Merchant Rules, which are available here. SpotOn has no responsibility for assuring that Merchants comply with the Merchant Rules, and you should consult each Merchant’s Privacy Policy to determine how that Merchant uses information relating to you. </p>
                       <p className='font-sans leading-[140%] text-[20px]'>SpotOn respects the personal privacy of Consumers. This Privacy Policy describes how SpotOn uses the information of people who visit a SpotOn website (a “SpotOn Website”), use one or more of SpotOn’s mobile apps (“SpotOn Mobile”), or interact with SpotOn’s technology used at Merchants’ sites (such as tablets and point-of-sale devices). This policy also applies to information submitted or uploaded to SpotOn by a Consumer (e.g., through Facebook, other social media sites or otherwise) or uploaded to SpotOn by a Merchant (each social media site or Merchant, a “SpotOn Access Portal”), and to information gathered by SpotOn as Consumers use any of the Services.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>This Privacy Policy does not apply to any use of your information by a Merchant, to any other services or websites that may be offered by a Merchant, or to any third-party sites that may be accessible from a SpotOn Access Portal or through the Services provided by SpotOn. Further, this Privacy Policy does not apply to Facebook1, Twitter, or any other third-party sites through which any SpotOn Services may be delivered. To understand how any third party treats your information (including your information related to the use of SpotOn Services), you should contact such third party or read its privacy policy. We encourage you to read this entire Privacy Policy before using a SpotOn <a className='no-underline text-primary' href="https://spoton.com" target='_blank'>Website</a>, the SpotOn Mobile, a SpotOn device located at a Merchant, or submitting or uploading information through any Merchant or any other SpotOn Access Portal.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>Do not use a SpotOn <a className='no-underline text-primary' href="https://spoton.com" target='_blank'>Website</a>, SpotOn Mobile, a SpotOn device located at a Merchant, or any other SpotOn Access Portal if you are unwilling to accept the terms and conditions of this Privacy Policy. By using SpotOn Access Portals or the Services, you agree to the terms of this Privacy Policy. When you submit information to SpotOn or use SpotOn’s services, you consent to the collection, storage, use, and disclosure of that information in accordance with this Privacy Policy. After reading this Privacy Policy, which is incorporated into and is a part of the <a className='no-underline text-primary' href="https://www.spoton.com/legal/user-terms/" target='_blank'>User Terms of Service</a> (“<a className='no-underline text-primary' href="https://www.spoton.com/legal/user-terms/" target='_blank'>User Terms</a>”), you will know:</p>
                       <Title level={4} className='text-[24px]' style={{ marginBottom: 42, marginTop: 56}}>Information SpotOn Collects From You.</Title>
                       <p className='font-sans font-bold text-[18px] mb-[24px]'>Active Information Collection.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>SpotOn actively collects information from our visitors to a SpotOn Website or SpotOn Mobile both by asking specific questions, by allowing you to upload information through other tools (such as Facebook Connect or other social media site), and by permitting you to communicate directly with us through a SpotOn Website, SpotOn Mobile, via email or otherwise. If you sign up with SpotOn to use the Services directly with SpotOn or through any SpotOn Access Portal (including a Merchant or social media site), or if a Merchant signs you up for its services that use SpotOn technology, some of the information that you submit (directly or via an upload), or that is submitted for you by a Merchant, is required to register you to use the Services. Some of such information could identify you personally, such as your first and/or last name, telephone number, email address, zip code and birthdate. Such information is called “Personally Identifiable Information.” Some Services or programs may require you (or a Merchant on your behalf) to enter your demographic information and/or additional Personally Identifiable Information, such as your name (if not previously provided), gender, financial / credit card information or other information.
                           SpotOn and Merchants may offer you, directly or through Facebook, Twitter, other social media sites, or other third parties, the opportunity to participate in activities related to the Services, such as responding to surveys, entering a contest or sweepstakes, playing games or providing information to our customer service representatives. If you choose to participate in such activities (when offered) SpotOn or Clients may collect additional Personally Identifiable Information or other information from you with your consent.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>When you visit a SpotOn Website, SpotOn Mobile, use the Services, or participate in activities that are part of the Services, SpotOn and Merchants may collect information related to such activities. SpotOn does not intentionally collect Personally Identifiable Information about you other than (i) such information that you provide us as described above, either directly to SpotOn or through a SpotOn Access Portal (including through permissions you authorize with social media sites which are used in conjunction with the Services (e.g., Facebook, Twitter and other sites)), or (ii) information submitted to SpotOn for you by one or more Merchants. SpotOn may, however, keep track of your responses to Offers (defined below), visits to and purchases from Merchants and associate that information with your registration information, including your Personally Identifiable Information (see “Program Information Collection,” below).</p>

                       <p className='font-sans font-bold mt-[60px] text-[18px]'>Passive Information Collection.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>As you navigate through a SpotOn Website, SpotOn Mobile or use the Services, SpotOn may collect certain information passively (that is, information may be gathered without you actively providing the information) using various technologies and means, such as navigational data collection and geolocation data. The foregoing data may also be collected when you interact with the SpotOn technology, for example, by making an appointment or reservation or making a payment.  Like most website operators, SpotOn, its advertisers and third-party service providers may track IP addresses, use both session and persistent cookies, and assign Internet tags. SpotOn also automatically gathers information about the areas you visit on a SpotOn Website and SpotOn Mobile and collects operational information about the technology you use, such as your browser, type of computer or mobile device, operating systems, Internet service provider and the domain names of the websites from which you linked to the SpotOn website or SpotOn Mobile and to which you go after viewing such sites. If you activate geolocation applications, SpotOn may have access to and store your geolocation information related to the Services.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>In addition, when you visit a SpotOn Website or SpotOn Mobile, SpotOn may store some information on your computer or mobile device. This information will be in the form of a cookie or similar file and will help us in many ways. For example, cookies allow us to tailor our websites, our mobile apps and the Services or other offerings to you in a way that better matches your interests and preferences. Most Internet browsers enable you to erase cookies from your computer hard drive, block all cookies, or receive a warning before a cookie is stored. Please refer to your browser instructions or help screen to learn more about these functions. SpotOn does not collect Personally Identifiable Information automatically, but we may tie information collected automatically to your previously submitted Personally Identifiable Information.</p>
                       <p className='font-sans leading-[140%] text-[20px]'>A SpotOn Website, SpotOn Mobile, or any other SpotOn interface that connects you to the Services may contain electronic images known as web beacons—also referred to as single-pixel gifs—that permit SpotOn to count users who have visited the website or mobile app and for other related statistics—(e.g., recording the popularity of certain content and verifying system and server integrity). Web beacons are not used by SpotOn to access your Personally Identifiable Information; they are used solely to compile aggregated statistics concerning the use of a SpotOn Website or SpotOn Mobile.  Web beacons collect only a limited set of information including a cookie number, the time and date of a page view, and a description of the page on which the web beacon resides.</p>
                       <p className='font-sans leading-[140%] text-[20px] mb-[80px]'>The reason SpotOn collects passive information is that it helps us understand which areas of a SpotOn Website and SpotOn Mobile are most popular, which areas may require improvement and what technologies are being used by our visitors. This information helps SpotOn to update and improve its websites and mobile apps, and our Services. SpotOn also uses this information for security purposes, to detect and to block security breaches and to provide you with a safer online environment.</p>
                   </div>
               </section>
           </div>
        </Layout>
    )
}



export default WhatWeDo
